import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Frontend } from "../../assets/images/svg/frontend.svg";
import { ReactComponent as UiDesigner } from "../../assets/images/svg/ui-designer.svg";
import { ReactComponent as TeamPlayer } from "../../assets/images/svg/team-player.svg";
import { Fade } from "react-reveal";
import "./styles.css";
import { Tilt } from "react-tilt";

export default function About() {
  const { t } = useTranslation();
  return (
    <Fade bottom>
      <div className="about-page container">
        <div className="row">
          <div className="col-2 about-info">
            <h1>{t("about.card1title")}</h1>
            <p>{t("about.card1desc")}</p>
            <div className="tools">
              <span>{t("about.card1tool1")}</span>
              <span>{t("about.card1tool2")}</span>
              <span>{t("about.card1tool3")}</span>
              <span>{t("about.card1tool4")}</span>
            </div>
          </div>
          <div className="col-2 about-image">
            <Tilt>
              <Frontend />
            </Tilt>
          </div>
        </div>
        <div className="row">
          <div className="col-2 about-image">
            <Tilt>
              <UiDesigner />
            </Tilt>
          </div>
          <div className="col-2 about-info">
            <h1>{t("about.card2title")}</h1>
            <p>{t("about.card2desc")}</p>
            <div className="tools">
              <span>{t("about.card2tool1")}</span>
              <span>{t("about.card2tool2")}</span>
              <span>{t("about.card2tool3")}</span>
              <span>{t("about.card2tool4")}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-2 about-info">
            <h1>{t("about.card3title")}</h1>
            <p>{t("about.card3desc")}</p>
            <div className="tools">
              <span>{t("about.card3tool1")}</span>
              <span>{t("about.card3tool2")}</span>
              <span>{t("about.card3tool3")}</span>
              <span>{t("about.card3tool4")}</span>
            </div>
          </div>
          <div className="col-2 about-image">
            <Tilt>
              <TeamPlayer />
            </Tilt>
          </div>
        </div>
      </div>
    </Fade>
  );
}
