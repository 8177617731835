import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as Cv } from "../../assets/images/svg/footer-link-cv.svg";
import { ReactComponent as Home } from "../../assets/images/svg/footer-link-home.svg";
import { ReactComponent as About } from "../../assets/images/svg/footer-link-about.svg";
import { ReactComponent as Projects } from "../../assets/images/svg/footer-link-projects.svg";
import { ReactComponent as Contact } from "../../assets/images/svg/footer-link-contact.svg";
import { ReactComponent as Blog } from "../../assets/images/svg/footer-link-blog.svg";
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";
import "./styles.css";

export default function Footer() {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  function toggleSvgAndText(path, text) {
    if (pathname === path) {
      return (
        <Fade bottom>
          <p>{t(`header.${text}`)}</p>
        </Fade>
      )
    }
  }

  return (
    <div className="footer">
      <ul className="footer-links">
        <li>
          <NavLink to="/">
            {toggleSvgAndText("/", "home") || <Home />}
          </NavLink>
        </li>
        <li>
          <NavLink to="/about">
            {toggleSvgAndText("/about", "about") || <About />}
          </NavLink>
        </li>
        <li>
          <NavLink to="/projects">
            {toggleSvgAndText("/projects", "projects") || <Projects />}
          </NavLink>
        </li>
        <li>
          <NavLink to="/blog">
            {toggleSvgAndText("/blog", "blog") || <Blog />}
          </NavLink>
        </li>
        <li>
          <NavLink to="/contact">
            {toggleSvgAndText("/contact", "contact") || <Contact />}
          </NavLink>
        </li>
      </ul>
    </div >
  );
}
