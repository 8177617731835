import { ReactComponent as WhatsApp } from "../../assets/images/svg/whats-app.svg";
import "./styles.css";

export default function WhatsIcon() {
  return (
    <a
      className="fixed-whats"
      href="https://api.whatsapp.com/send/?phone=201207401576"
      target="_blank"
      rel="noreferrer"
    >
      <WhatsApp />
    </a>
  );
}
