import { configureStore } from "@reduxjs/toolkit";
import themeModeReducer from "./theme-slice";

const store = configureStore({
  reducer: {
    toggleMode: themeModeReducer,
  },
});

export default store;
