import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { checkFixLang } from "./locales/lang";
import { Routes, Route, Navigate } from "react-router-dom";
import i18n from "./locales/i18n";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Projects from "./pages/Projects";
import Blog from "./pages/Blog";
import Cv from "./pages/Cv";
import Layout from "./layouts/Layout";
import bgVideo from "./assets/images/bg-video.mp4";
import bgVideoPoster from "./assets/images/bg-video-img.svg";

function App() {
  const mode = useSelector((state) => state.toggleMode.isDarkMode);
  const lang = i18n.language;
  useEffect(() => {
    checkFixLang(lang);
    if (mode) {
      document.body.classList.add("dark-mode");
      document.body.setAttribute("data-theme", "dark");
      document.documentElement.setAttribute("data-theme", "dark");
    } else {
      document.body.classList.remove("dark-mode");
      document.body.setAttribute("data-theme", "light");
      document.documentElement.setAttribute("data-theme", "light");
    }

    return () => {
      checkFixLang(lang);
    };
  }, [lang, mode]);

  return (
    <>
      <video autoPlay loop muted poster={bgVideoPoster}>
        <source src={bgVideo} type="video/mp4" />
      </video>
      <Routes>
        <Route element={<Layout />}>
          <Route index path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/cv" element={<Cv />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
