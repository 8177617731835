import { createSlice } from "@reduxjs/toolkit";

const ThemeSlice = createSlice({
  name: "themeReducer",
  initialState: {
    isDarkMode: localStorage.getItem("isDarkMode") === "true" ? true : false,
  },
  reducers: {
    toggleMode(state) {
      state.isDarkMode = !state.isDarkMode;
      localStorage.setItem("isDarkMode", state.isDarkMode);
      document.documentElement.setAttribute(
        "data-theme",
        state.isDarkMode ? "dark" : "light"
      );
      document.body.setAttribute(
        "data-theme",
        state.isDarkMode ? "dark" : "light"
      );
    },
  },
});

export const { toggleMode } = ThemeSlice.actions;
export default ThemeSlice.reducer;
