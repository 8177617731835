import React, { useEffect, useState, useRef } from "react";
import { Tilt } from 'react-tilt'
import { Fade } from "react-reveal";
import Isotope from "isotope-layout";
import items from "./data";
import "./styles.css";

export default function Projects() {
  const isotope = useRef();
  const [filterKey, setFilterKey] = useState("*");
  useEffect(() => {
    isotope.current = new Isotope(".filter-container", {
      itemSelector: ".filter-item",
      layoutMode: "fitRows",
    });
    return () => isotope.current.destroy();
  }, []);

  useEffect(() => {
    filterKey === "*"
      ? isotope.current.arrange({ filter: `*` })
      : isotope.current.arrange({ filter: `.${filterKey}` });
  }, [filterKey]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const itemsTitles = {
    "*": "All",
    frontend: "Frontend",
    backend: "Backend",
    ui: "UI/UX",
  };

  return (
    <Fade bottom>
      <div className="projects-page container">
        <ul className="filter-ul">
          {Object.keys(itemsTitles).map((title) => (
            <li key={title} onClick={handleFilterKeyChange(title)} className={filterKey == title ? "active" : ""}>
              {title === "*" ? "All" : itemsTitles[title]}
            </li>
          ))}
        </ul>
        <ul className="filter-container">
          {items?.map((item) => (
            <li className={`filter-item ${item.type}`} key={item.id} onClick={() => { window.open(item.link, "_blank"); }}>
              <Tilt className="filter-item-content">
                <img loading={"lazy"} src={item.image} alt={item.title} />
              </Tilt>
            </li>
          ))}
        </ul>
      </div>
    </Fade>
  );
}
