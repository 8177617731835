const items = [
  {
    id: 1,
    type: "frontend ui",
    title: "CDS",
    image: require("../../assets/images/projects/CDS.png"),
    link: "https://omarahmed8k.github.io/CDS/",
  },
  {
    id: 2,
    type: "frontend ui",
    title: "Qsimh",
    image: require("../../assets/images/projects/Qsimh.png"),
    link: "https://omarahmed8k.github.io/Frontend-Qsimh/",
  },
  {
    id: 7,
    type: "frontend ui",
    title: "Edrak",
    image: require("../../assets/images/projects/Edrak.png"),
    link: "https://www.edsacad.com/",
  },
  {
    id: 5,
    type: "frontend ui",
    title: "Bnan",
    image: require("../../assets/images/projects/Bnan.png"),
    link: "https://bnan.vercel.app/",
  },
  {
    id: 3,
    type: "frontend",
    title: "Nabra",
    image: require("../../assets/images/projects/Nabra.png"),
    link: "https://omarahmed8k.github.io/Nabra/",
  },
  {
    id: 4,
    type: "frontend",
    title: "AlZawaied",
    image: require("../../assets/images/projects/AlZawaied.png"),
    link: "https://omarahmed8k.github.io/alzawahed/",
  },
  {
    id: 6,
    type: "frontend",
    title: "Ghayat",
    image: require("../../assets/images/projects/Ghayat.png"),
    link: "https://ghayat.org/",
  },
  {
    id: 8,
    type: "backend",
    title: "Todo",
    image: require("../../assets/images/projects/Todo.png"),
    link: "https://auth-to-do-with-express.vercel.app",
  },
];

export default items;
