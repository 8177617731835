import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import i18n from "../../locales/i18n";
import { ReactComponent as Menu } from "../../assets/images/svg/menu.svg";
import { ReactComponent as DarkMode } from "../../assets/images/svg/dark-mode.svg";
import logoEn from "../../assets/images/logo-en.png";
import logoAr from "../../assets/images/logo-ar.png";
import logoArDark from "../../assets/images/logo-ar-dark.png";
import logoEnDark from "../../assets/images/logo-en-dark.png";
import { useDispatch, useSelector } from "react-redux";
import { toggleMode } from "../../store/theme-slice";
import "./styles.css";

export default function Header() {
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.toggleMode.isDarkMode);

  const lang = i18n.language;
  const { t } = useTranslation();
  const [sidebar, setSidebar] = useState(false);

  return (
    <div className="header">
      <div className="container">
        <div className="header-content">
          <div className="header-logo">
            <NavLink to="/">
              {lang === "ar" ? (
                mode ? (
                  <img src={logoArDark} alt="logo" />
                ) : (
                  <img src={logoAr} alt="logo" />
                )
              ) : mode ? (
                <img src={logoEnDark} alt="logo" />
              ) : (
                <img src={logoEn} alt="logo" />
              )}
            </NavLink>
          </div>
          <ul className="header-links">
            <li className="cv-btn">
              <NavLink className="outline-btn contact" to="/cv">
                {t("header.cv")}
              </NavLink>
            </li>
            <li className="dark-mode">
              <DarkMode
                onClick={() => {
                  dispatch(toggleMode());
                }}
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
