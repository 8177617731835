import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Language from "../components/Language";
import WhatsIcon from "../components/WhatsIcon";


export default function Layout() {
  return (
    <>
      <Header />
      <Language />
      <div className="page">
        <Outlet />
      </div>
      <Footer />
      <WhatsIcon />
    </>
  );
}
