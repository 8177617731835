import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";
import emailjs from "emailjs-com";
import sweetAlert from "../../helpers/sweetAlert";
import "./styles.css";

export default function Contact() {
  const form = useRef();
  const { t } = useTranslation();
  const [submitted, setsubmitted] = useState(false);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [message, setmessage] = useState("");

  const reset = () => {
    setname("");
    setemail("");
    setphone("");
    setmessage("");
  };

  const sendEmail = (e) => {
    e.preventDefault();
    let button = e.target[4];

    if (name === "" || email === "" || phone === "" || message === "") {
      sweetAlert.error(t("contact.fill"));
      button.disabled = false;
    } else {
      button.disabled = true;
      emailjs.sendForm("service_mut7hm7", "template_fxh174r", form.current, "user_gz4FeI4bF67bjjX8bl4RN").then(
        () => {
          sweetAlert.success(t("contact.success"));
          setsubmitted(true);
          reset();
          setTimeout(() => { setsubmitted(false); }, 5000);
        },
        () => {
          sweetAlert.error(t("contact.error"));
          button.disabled = false;
        }
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        setname(value);
        break;
      case "email":
        setemail(value);
        break;
      case "phone":
        setphone(value);
        break;
      case "message":
        setmessage(value);
        break;
      default:
        break;
    }
  };

  return (
    <Fade bottom>
      <div className="contact-page container">
        {submitted ? (
          <div className="results-info">
            <h1>{t("contact.sendTitle")}</h1>
            <p>{t("contact.sendText")}</p>
          </div>
        ) : (
          <>
            <h1>{t("contact.title")}</h1>
            <p>{t("contact.subtitle")}</p>
            <form ref={form} onSubmit={sendEmail} className="row contact-form">
              <div className="col-3 input-group">
                <label htmlFor="name">{t("contact.name")}</label>
                <input
                  name="name"
                  type="text"
                  id="name"
                  onChange={handleChange}
                />
              </div>
              <div className="col-3 input-group">
                <label htmlFor="name">{t("contact.email")}</label>
                <input
                  name="email"
                  type="email"
                  id="email"
                  onChange={handleChange}
                />
              </div>
              <div className="col-3 input-group">
                <label htmlFor="name">{t("contact.phone")}</label>
                <input
                  name="phone"
                  type="tel"
                  id="number"
                  onChange={handleChange}
                />
              </div>
              <div className="col-1 input-group">
                <label htmlFor="name">{t("contact.message")}</label>
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="10"
                  onChange={handleChange}
                />
              </div>
              <input
                type="submit"
                value={t("contact.send")}
                className="main-btn"
              />
            </form>
          </>
        )}
      </div>
    </Fade>
  );
}
