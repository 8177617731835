import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
import { Tilt } from "react-tilt";
import ContactIcons from "../../components/ContactIcons";
import homeImg from "../../assets/images/home-img.png";
import ReactTyped from "react-typed";
import "./styles.css";

export default function Home() {
  const { t } = useTranslation();
  return (
    <Fade bottom>
      <div className="home-page container">
        <div className="row">
          <div className="col-2 home-info">
            <h1>
              <ReactTyped
                className="typed"
                strings={[t("home.title1"), t("home.title2")]}
                backDelay={5000}
                typeSpeed={50}
                backSpeed={50}
                loop
              />
            </h1>
            <p>{t("home.desc")}</p>
            <div className="btns">
              <Link to={"/cv"} className="btn main-btn">
                {t("home.btn1")}
              </Link>
              <Link to={"/contact"} className="btn outline-btn">
                {t("home.btn2")}
              </Link>
            </div>
          </div>
          <div className="col-2 home-image">
            <Tilt>
              <img src={homeImg} alt="omar-img" />
            </Tilt>
            <h4>{t("home.username")}</h4>
            <ContactIcons />
          </div>
        </div>
      </div>
    </Fade>
  );
}
