import { ReactComponent as Github } from "../../assets/images/svg/github.svg";
import { ReactComponent as Behance } from "../../assets/images/svg/behance.svg";
import { ReactComponent as Facebook } from "../../assets/images/svg/facebook.svg";
import { ReactComponent as Twitter } from "../../assets/images/svg/twitter.svg";
import { ReactComponent as Linkedin } from "../../assets/images/svg/linkedin.svg";
import "./styles.css";

export default function ContactIcons() {
  return (
    <div className="row icons">
      <div className="col-5">
        <Facebook
          onClick={() => {
            window.open("https://www.facebook.com/omarahmed8k", "_blank");
          }}
        />
      </div>
      <div className="col-5">
        <Twitter
          onClick={() => {
            window.open("https://twitter.com/omarahmed8k", "_blank");
          }}
        />
      </div>
      <div className="col-5">
        <Github
          onClick={() => {
            window.open("https://github.com/omarahmed8k", "_blank");
          }}
        />
      </div>
      <div className="col-5">
        <Behance
          onClick={() => {
            window.open("https://www.behance.net/omarahmed8k", "_blank");
          }}
        />
      </div>
      <div className="col-5">
        <Linkedin
          onClick={() => {
            window.open(
              "https://www.linkedin.com/in/omarahmed8k/",
              "_blank"
            );
          }}
        />
      </div>
    </div>
  );
}
