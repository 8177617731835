import React from "react";
import { Fade } from "react-reveal";
import "./styles.css";
import { useTranslation } from "react-i18next";

export default function Blog() {
  const { t } = useTranslation();
  return (
    <Fade bottom>
      <div className="blog-page container">
        <iframe src="https://reusable-components-sigma.vercel.app/reusable-components" frameborder="0"></iframe>
        <a href="https://reusable-components-sigma.vercel.app/reusable-components" target="_blank">
          {t("blog.link")}
        </a>
      </div>
    </Fade>
  );
}
