import React from "react";
import LoadingSpin from "../../components/LoadingSpin";
import "./styles.css";

export default function Cv() {
  return (
    <>
      <LoadingSpin />
      <iframe
        className="cv-page"
        src="https://drive.google.com/file/d/19TZxTwK-kRA6kZeS23D9WMUYEmKmgDEU/preview"
        allow="autoplay"
        title="CV"
      ></iframe>
    </>
  );
}
